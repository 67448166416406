import projectsElements from "../../styles/components/projectsElements.module.scss";
import handyman from "../../images/projects/handyman.png";
import {Col, Row} from "react-bootstrap";
import react from "../../images/technologies/react.png";
import nodejs from "../../images/technologies/nodejs.png";
import {paths} from "../../storage/paths";
import {NavLink} from "react-router-dom";
import StatusCompletedButton from "../buttons/StatusCompletedButton";

function UsaHandyman() {
    return(
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className={projectsElements.project}>
            <NavLink to={paths.UsaHandyman} className={projectsElements.link}>
                <img src={handyman} className={projectsElements.projects_images}/>
                <h6>Handyman</h6>
            </NavLink>

            <Row>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                    <StatusCompletedButton/>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                    <img src={react} className={projectsElements.projects_icons}/>
                    <img src={nodejs} className={projectsElements.projects_icons}/>
                </Col>
            </Row>
        </Col>
    );
}

export default UsaHandyman;
import {Col, Container, Row} from "react-bootstrap";
import elements from "../../styles/settings/elements.module.scss";
import Technologies from "../../components/Technologies";

function TechnologiesPage(){
    return(
        <Container className={elements.container}>
            <p>As a Full-Stack Developer I use various tools for my applications. These are the main ones.</p>

            <h3 className={elements.section}>Technologies</h3>
            <hr className={elements.section_hr}/>

            <Technologies/>
        </Container>
    );
}

export default TechnologiesPage;
import classes from "../../styles/pages/projects.module.scss";
import {BsGlobe} from "react-icons/bs";
import {Container} from "react-bootstrap";
import html from "../../images/technologies/html.png";
import css from "../../images/technologies/css.png";
import bootstrap from "../../images/technologies/bootstrap.png";
import js from "../../images/technologies/js.png";
import sass from "../../images/technologies/sass.png";
import react from "../../images/technologies/react.png";
import redux from "../../images/technologies/redux.png";
import nodejs from "../../images/technologies/nodejs.png";
import express from "../../images/technologies/express.png";
import mongodb from "../../images/technologies/mongodb.png";
import chatgpt from "../../images/technologies/chatgpt.png";
import StatusDevelopmentButton from "../../components/buttons/StatusDevelopmentButton";
import StatusPersonalProjectButton from "../../components/buttons/StatusPersonalProjectButton";

function AIChatBotPage() {
    return(
        <>
            <div className={`${classes.imageBlock} ${classes.imageBlock}`}>
                <div className={classes.imageBlockContent}>
                    <h2 className={classes.pageTitle}>AI Chat Bot</h2>
                    <p className={classes.pageDescription}>ChatGPT bot for idiyas.com</p>
                    <a href="https://idiyas.com/ai-bot" target="_blank" className={classes.link}><button className={classes.visitWebsite}><BsGlobe className={classes.visitIcon}/> Visit Website</button></a>
                </div>
            </div>

            <Container>
                <h3 className={classes.bigTitle}>Technologies</h3>
                <div className={classes.technologies_icons_row}>
                    <div className={classes.technologies_icons_col}>
                        <img src={html} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={css} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={bootstrap} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={js} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={sass} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={react} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={redux} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={nodejs} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={express} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={mongodb} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={chatgpt} className={`${classes.technologies_icons} ${classes.big_icon}`}/>
                    </div>
                </div>

                <h3 className={classes.bigTitle}>About</h3>
                <p>AI Chat Bot Assistant based on ChatGPT API for the website idiyas. Assistant can answer simple questions based on its knowledge. Also, more complicated and  changing information like top inventors based on idiyas database.</p>
                <StatusDevelopmentButton/>
                &#8195;
                &#8195;
                <StatusPersonalProjectButton/>

                <h3 className={classes.bigTitle}>Review</h3>
            </Container>
        </>
    );
}

export default AIChatBotPage;
import {Col, Container, Row} from "react-bootstrap";
import classes from "../../styles/pages/projects.module.scss";
import StatusCompletedButton from "../../components/buttons/StatusCompletedButton";
import html from "../../images/technologies/html.png";
import css from "../../images/technologies/css.png";
import bootstrap from "../../images/technologies/bootstrap.png";
import js from "../../images/technologies/js.png";
import sass from "../../images/technologies/sass.png";
import react from "../../images/technologies/react.png";
import spcreditLandingMainPage from "../../images/projects/spcredit_landing_mainpage.png";
import spcreditLandingWindow from "../../images/projects/spcredit_landing_window.png";
import {BsGlobe} from "react-icons/bs";
import StatusPersonalProjectButton from "../../components/buttons/StatusPersonalProjectButton";

function SPCreditLandingPage() {
    return(
        <>
            <div className={`${classes.imageBlock} ${classes.imageBlock}`}>
                <div className={classes.imageBlockContent}>
                    <h2 className={classes.pageTitle}>SPCredit</h2>
                    <p className={classes.pageDescription}>Landing Page Website for the Players to Take In-Game Credits</p>
                    <a href="https://spcredit.maxwebdev.tech" target="_blank" className={classes.link}><button className={classes.visitWebsite}><BsGlobe className={classes.visitIcon}/> Visit Website</button></a>
                </div>
            </div>

            <Container>
                <h3 className={classes.bigTitle}>Technologies</h3>
                <div className={classes.technologies_icons_row}>
                    <div className={classes.technologies_icons_col}>
                        <img src={html} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={css} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={bootstrap} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={js} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={sass} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={react} className={classes.technologies_icons}/>
                    </div>
                </div>

                <h3 className={classes.bigTitle}>About</h3>
                <p>Website was developed for one of the biggest Minecraft RP server in Russia. Players can take online credits with in-game currency. Website has hundreds of visitors and dozens of clients. Also, website is translated to 2 languages (EN, RU) and shows a language due to user's default language.</p>
                <StatusCompletedButton/>
                &#8195;
                &#8195;
                <StatusPersonalProjectButton/>

                <h3 className={classes.bigTitle}>Review</h3>
                <Row>
                    <h5 className={classes.smallTitle}>Main Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Page contains inputs for user to fill in his data and calculate total credit percentage. As data changes that affects percent and it's color. If user complete filling in the data he can press apply button. In case user missed any input, it will turn red to show what is wrong.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={spcreditLandingMainPage} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Window</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>If user clicks a button and all inputs are correct then he gets a window with instructions of what he should do in the game to obtain his credit. He can close a window after.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={spcreditLandingWindow} className={classes.reviewImage}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SPCreditLandingPage;
import Header from "../components/Header";
import {Container} from "react-bootstrap";

function PageContainer(props){
    return(
        <>
            <Header/>
            {props.children}
        </>
    );
}

export default PageContainer;
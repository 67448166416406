import classes from "../../styles/pages/projects.module.scss";
import {BsGlobe} from "react-icons/bs";
import {Col, Container, Row} from "react-bootstrap";
import html from "../../images/technologies/html.png";
import css from "../../images/technologies/css.png";
import bootstrap from "../../images/technologies/bootstrap.png";
import js from "../../images/technologies/js.png";
import sass from "../../images/technologies/sass.png";
import react from "../../images/technologies/react.png";
import redux from "../../images/technologies/redux.png";
import StatusDevelopmentButton from "../../components/buttons/StatusDevelopmentButton";
import StatusHackathonProjectButton from "../../components/buttons/StatusHackathonProjectButton";
import smartpayStart from "../../images/projects/smartpay_start.png";
import smartpayRegister from "../../images/projects/smartpay_register.png";
import smartpayLogin from "../../images/projects/smartpay_login.png";
import smartpayChooseCategories from "../../images/projects/smartpay_choose_categories.png";
import smartpayChooseShops from "../../images/projects/smartpay_choose_shops.png";
import smartpayHomePage from "../../images/projects/smartpay_homepage.png";
import smartpayNotifications from "../../images/projects/smartpay_notifications.png";
import smartpayShops from "../../images/projects/smartpay_shops.png";
import smartpayCategories from "../../images/projects/smartpay_categories.png";
import smartpayChat from "../../images/projects/smartpay_chat.png";
import smartpaySettings from "../../images/projects/smartpay_settings.png";

function SmartPayPage() {
    return(
        <>
            <div className={`${classes.imageBlock} ${classes.imageBlock}`}>
                <div className={classes.imageBlockContent}>
                    <h2 className={classes.pageTitle}>Smart Pay</h2>
                    <p className={classes.pageDescription}>Service Provides Financial Help For Users</p>
                    <a href="https://smartpay.maxwebdev.tech" target="_blank" className={classes.link}><button className={classes.visitWebsite}><BsGlobe className={classes.visitIcon}/> Visit Website</button></a>
                </div>
            </div>

            <Container>
                <h3 className={classes.bigTitle}>Technologies</h3>
                <div className={classes.technologies_icons_row}>
                    <div className={classes.technologies_icons_col}>
                        <img src={html} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={css} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={bootstrap} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={js} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={sass} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={react} className={classes.technologies_icons}/>
                    </div>
                    <div className={classes.technologies_icons_col}>
                        <img src={redux} className={classes.technologies_icons}/>
                    </div>
                </div>

                <h3 className={classes.bigTitle}>About</h3>
                <p>Part of a project developed for a programming competition and this application has only frontend part. Service to help users in a financial sphere.</p>
                <StatusDevelopmentButton/>
                &#8195;
                &#8195;
                <StatusHackathonProjectButton/>

                <h3 className={classes.bigTitle}>Review</h3>
                <Row>
                    <h5 className={classes.smallTitle}>Start Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>First page where you can choose authentication method.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayStart} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Register</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Authenticate via register. Create login and double check password.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayRegister} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Login</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Authenticate via login. Write your login and password.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayLogin} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Choose Categories</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Choose categories most suitable for you.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayChooseCategories} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Choose Shops</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Choose your favorite shops from categories selected.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayChooseShops} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Home Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Home Page contains lots of information starting from header and navigation menu and up to advertisement blocks and list of purchases.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayHomePage} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Notifications</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Notification window can be opened clicking on a button in header. To close window user can just click on dark background around it.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayNotifications} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Shops Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>List of shops in different categories with rating.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayShops} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Categories Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Change selected categories.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayCategories} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Assistant Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>Chat with Financial Assistant. User can write in a chat or send a message with fast-message button above the text area.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpayChat} className={classes.reviewImage}/>
                    </Col>
                </Row>
                <Row>
                    <h5 className={classes.smallTitle}>Settings Page</h5>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <p className={classes.reviewText}>User can edit login and name here, change app theme and exit to the start page.</p>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={smartpaySettings} className={classes.reviewImage}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SmartPayPage;
import classes from "../styles/components/header.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import {paths} from "../storage/paths";
import {NavLink} from "react-router-dom";
import {AiFillGithub, AiFillLinkedin} from "react-icons/ai";
import {MdOutlineCookie} from "react-icons/md";
import {TbBrandUpwork} from "react-icons/tb";
import {SiTelegram} from "react-icons/si";

function Header(){
    return(
        <>
            <Container className={classes.header_layout}>
                <Row>
                    <Col xl={{span: 4, order: 1}} lg={{span: 4, order: 1}} md={{span: 6, order: 1}} sm={{span: 6, order: 1}} xs={{span: 6, order: 1}} className={classes.portfolio}>
                        <NavLink to={paths.homePage} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                            <MdOutlineCookie className={classes.portfolioIcon}/>Portfolio
                        </NavLink>
                    </Col>

                    <Col xl={{span: 4, order: 2}} lg={{span: 4, order: 2}} md={{span: 12, order: 3}} sm={{span: 12, order: 3}} xs={{span: 12, order: 3}} className={classes.links}>
                        <NavLink to={paths.projects} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                            Projects
                        </NavLink>
                        <NavLink to={paths.technologies} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                            Technologies
                        </NavLink>
                        <NavLink to={paths.about} className={({isActive}) => (isActive ? classes.menu_btn_active : classes.menu_btn_inactive)}>
                            About
                        </NavLink>
                    </Col>

                    <Col xl={{span: 3, order: 3}} lg={{span: 3, order: 3}} md={{span: 6, order: 2}} sm={{span: 6, order: 2}} xs={{span: 6, order: 2}} className={classes.icons_container}>
                        <a href="https://t.me/MaxSemukhin" target="_blank"><SiTelegram className={classes.menu_icons}/></a>
                        <a href="https://github.com/MaxSemukhin" target="_blank"><AiFillGithub className={classes.menu_icons}/></a>
                        <a href="https://www.linkedin.com/in/max-semukhin-a01b46265/" target="_blank"><AiFillLinkedin className={classes.menu_icons}/></a>
                        {/*<a href="https://www.upwork.com/freelancers/~016113517ac891d613?viewMode=1"target="_blank"><TbBrandUpwork className={classes.menu_icons}/></a>*/}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Header;
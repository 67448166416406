import {Container, Row} from "react-bootstrap";
import classes from "../../styles/pages/homePage.module.scss";
import elements from "../../styles/settings/elements.module.scss";
import {paths} from "../../storage/paths";
import {NavLink} from "react-router-dom";
import AIChatBot from "../../components/projects/AIChatBot";
import SmartPay from "../../components/projects/SmartPay";
import UsaHandyman from "../../components/projects/UsaHandyman";
import Technologies from "../../components/Technologies";

function HomePage(){
    return(
        <Container className={elements.container}>
            <h3 className={classes.title}>Personal Portfolio</h3>
            <p className={classes.description}>Full-Stack Web Developer React & Node.JS</p>
            <NavLink to={paths.about}><button className={classes.aboutButton}>More About Me</button></NavLink>

            <NavLink to={paths.projects} className={classes.link}><h3 className={elements.section}>Projects</h3></NavLink>
            <hr className={elements.section_hr}/>

            <Row>
                <AIChatBot/>
                <SmartPay/>
                <UsaHandyman/>
            </Row>

            <NavLink to={paths.technologies} className={classes.link}><h3 className={elements.section}>Technologies</h3></NavLink>
            <hr className={elements.section_hr}/>

            <Technologies/>
        </Container>
    );
}

export default HomePage;